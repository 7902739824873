import React from 'react'
import useUtms from 'src/hooks/useUtms'

import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'

import Hero from './sections/hero/_index'
import Benefits from './sections/benefits/_index'
import Adivisor from './sections/advisor/_index'
import Exclusive from './sections/exclusive/_index'
import Company from './sections/company/_index'
import KnowMore from './sections/know-more/_index'

import { Wrapper } from './style'

const Pro = () => {
  const utmLink = useUtms({ link: `${process.env.OPEN_ACCOUNT_PJ}`, campanha: '' })

  const openAccountPJFormModal = () => {
    window.location.href = utmLink
  }
  return (
    <Wrapper>
      <Layout handleHeaderOpenAccount={() => openAccountPJFormModal()} pageContext={pageContext}>
        <Hero />
        <Benefits />
        <Adivisor />
        <Exclusive />
        <Company />
        <KnowMore />
      </Layout>
    </Wrapper>
  )
}

export default Pro
