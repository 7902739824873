import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`
  min-height: 319px;
  display: flex;
  align-items: center;

  h3 {
    margin-bottom: 16px;
  }

  @media (min-width: ${breakpoints.md}) {
    min-height: 210px;
  }
  @media (min-width: ${breakpoints.lg}) {
    min-height: 359px;
  }
  @media (min-width: ${breakpoints.xl}) {
    min-height: 473px;
  }
`
