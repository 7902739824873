import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { green } from 'src/styles/colors'

export const Section = styled.section`
  min-height: 587px;
  display: flex;
  align-items: center;
  background-color: ${green[100]};

  h2 {
    margin-bottom: 16px;
    margin-top: 24px;
  }

  @media (min-width: ${breakpoints.md}) {
    min-height: 430px;

    h2 {
      margin-top: 0;
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    min-height: 520px;
  }
  @media (min-width: ${breakpoints.xl}) {
    min-height: 667px;
  }
`
