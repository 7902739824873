import React from 'react'

import { Section } from './style'

const Adivisor = () => {
  return (
    <Section
      className='py-4'
      role='img'
      aria-label='Advisor Inter Pro, sorridente, de braços cruzados.'
    >
      <div className='container'>
        <div className='row justify-content-end'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500'>
              Um Advisor PJ Pro focado na sua empresa
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--400'>
              Para auxiliar com o dia a dia do seu negócio, recomendar serviços, ou facilitar qualquer processo no Super App, seu Advisor está sempre a poucos cliques de distância, para realmente simplificar a vida do seu negócio.
            </p>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Adivisor
