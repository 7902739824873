import { breakpoints } from 'src/styles/breakpoints'
import styled from 'styled-components'

import bgSm from '../../assets/image/lpPjProAdvisorBgSm.png'
import bgMd from '../../assets/image/lpPjProAdvisorBgMd.png'
import bgLg from '../../assets/image/lpPjProAdvisorBgLg.png'
import bgXl from '../../assets/image/lpPjProAdvisorBgXl.png'

export const Section = styled.section`
  min-height: 600px;
  display: flex;
  align-items: flex-end;
  background-image: url(${bgSm});
  background-repeat: no-repeat;
  background-size: contain;

  h2 {
    margin-bottom: 16px;
  }

  @media (min-width: ${breakpoints.md}) {
    background-image: url(${bgMd});
    background-repeat: no-repeat;
    background-size: contain;
    align-items: center;
    min-height: 339px;
  }
  @media (min-width: ${breakpoints.lg}) {
    background-image: url(${bgLg});
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 456px;
  }
  @media (min-width: ${breakpoints.xl}) {
    background-image: url(${bgXl});
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 648px;
  }
`
