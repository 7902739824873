import styled, { css } from 'styled-components'
import { green, grayscale, white, orange } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

import bgMd from '../../assets/image/lpPjPropHeroBgMd.png'
import bgLg from '../../assets/image/lpPjPropHeroBgLg.png'
import bgXl from '../../assets/image/lpPjPropHeroBgXl.png'

type buttonProps = {
  reverse?: boolean;
}

export const Section = styled.section`
  min-height: 376px;
  display: flex;
  align-items: center;

  p {
    color: ${grayscale[500]};
    margin-bottom: 32px;
  }

  @media(min-width: ${breakpoints.md}) {
    background-image: url(${bgMd});
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 347px;

    p {
      color: ${white};
    }
  }
  @media(min-width: ${breakpoints.lg}) {
    background-image: url(${bgLg});
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 471px;
  }
  @media(min-width: ${breakpoints.xl}) {
    background-image: url(${bgXl});
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 815px;

    p {
      margin-bottom: 40px;
    }
  }
`
export const Title = styled.h1`
  color: ${grayscale[500]};
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 16px;
  font-weight: 600;
  
  span {
    color: ${green[600]};
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    display: block;
  }
  
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 8px;
    color: ${white};
    font-size: 20px;
    line-height: 25px;  

    span {
      color: ${white};
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 16px;

    span {
      font-size: 32px;
      line-height: 40px;
    }
  }
  @media (min-width: ${breakpoints.xl}) {
    font-size: 32px;
    line-height: 40px;

    span {
      font-size: 40px;
      line-height: 50px;
    }
  }
`
export const ButtonLink = styled.a`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(buttonProps: buttonProps) => buttonProps.reverse ? 'transparent' : orange.extra};
  color: ${(buttonProps: buttonProps) => buttonProps.reverse ? orange.extra : white};
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  border-radius: 8px;
  ${(buttonProps: buttonProps) => buttonProps.reverse && css`
    border: 1px solid #FF7A00;
    margin-top: 16px;
  `}

  @media (min-width: ${breakpoints.lg}) {
    font-size: 14px;
    line-height: 18px;
  }

  &:hover {
    outline: none;
    text-decoration: none;
    color: ${(buttonProps: buttonProps) => buttonProps.reverse ? orange.extra : white};
  }
`
